.navbar > .container > .collapse:not(.show) {
	display: none;
}

.navbar > .container > .collapse, .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}

.container > .collapse > .navbar-nav > .nav-item {
	margin-right: 0px;
}

.na-book-appointment-navitem {
	position: fixed;
	right: 40px;
	top: 0px;
}

.na-book-appointment-navitem::after {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 30px 90px 0 90px;
	border-color: #b8a097 transparent transparent transparent;
}

.navbar-nav .nav-item:not(:last-child) {
	padding-right: 20px;
}

.landing-page .nav-item #navbar-home {
	display: none;
}

.na-book-appointment a, a:hover, a:visited {
	text-decoration: none;
}

.na-book-appointment {
	color: white;
	font-size: 18px;
	font-weight: bold;
	padding-top: 15px;
    background-color: #b8a097;
    width: 180px !important;
	text-align: center;
	transition: padding-top 0.35s ease-out;
	overflow: hidden;
}

.na-book-appointment:hover {
	padding-top: 40px;
	transition: padding-top 0.5s ease-in;
}

.na-navbar {
	margin-right: 180px;
	margin-left: 180px;
	font-size: 1.3em;
}

.na-top {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}

.navbar-dark .na-navbar-toggler { 
	border: none;
	padding-right: 45px;
}

@media (max-width:991px){

	.navbar-nav {
		margin-top: 18.2px;
	}

	.landing-page .navbar-nav {
		background-color: black;
		opacity: 50%;
		position: relative;
		width: 100px;
		text-align: right;
	}

	.landing-page .navbar > .container > .collapse {
		height: 120px;
	}

	.landing-page .navbar .navbar-nav .nav-link {
		font-weight: 600;
	}

	.navbar-nav .nav-item:not(:last-child) {
		display: block;
		padding-right: 0px;
	}
	
	.na-dark-background  .navbar-nav {
		background-color: #5d6754;
		position: relative;
		width: 120px;
	}

	.na-book-appointment  {
		display: none;
	}

	.na-book-appointment-navitem::after {
		display: none;
	}

	.collapsing {
		top: 0px;
		left: 0px;
		width: 220px;
		overflow: hidden;
		/* transition: height .5s ease; */
		-moz-transition: height .5s ease-in-out; 
     	-webkit-transition: height .5s ease-in-out; 
     	-o-transition: height .5s ease-in-out; 
		transition: height .5s ease-in-out;
		backface-visibility: hidden;
		-webkit-backface-visibility: hidden;
	}

	.navbar > .container > .collapse {
		height: 220px;
	}

	.navbar > .container > .collapsing {
		width: 220px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-end;
		border-radius: 5px;
	}

	.na-navbar {
		margin-right: 0px;
		margin-left: 0px;
	}
}

/* for phones */
@media (max-width: 415px) {
	.na-book-appointment {
		display: none;
	}

	.na-book-appointment-navitem::after {
		display: none;
	}

	.navbar-nav {
		border-radius: 5px;
	}

}