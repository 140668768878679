
@font-face 
{
	font-family: JustWrite;
	src: url("../fonts/just-write-solid.otf") format("opentype");
	font-display: swap;
}

@font-face 
{
	font-family: ThirstyScript;
	src: url("../fonts/ThirstyScriptExtraBoldDemo.otf") format("opentype");
	font-display: swap;
}

@font-face 
{
	font-family: Brittany;
	src: url("../fonts/brittany.otf") format("opentype");
	font-display: swap;
}

html {
    scroll-behavior: smooth;
}

body {
	color: #5d6754;
}

.page-header .na-content-center {
	max-width: 1000px;
	width: 80%;
}

.react-parallax-content {
	background-color: rgba(0, 0, 0, 0.3);
}

.na-block-title {
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 600;
}

.bg-info {
	background-color: #a5b53d !important;
}

.na-navMenuBackground {
	height: 70px;
	background-color: #5d6754;
}

.na-dark-background {
	background-color: #5d6754;
}

@media (max-width:991px){
	.na-lead-logo {
		display:none;
	}
	
	.navbar-nav .nav-item:not(:last-child)  {
		display:none;
	}

	.section {
		padding-left: 15px;
		padding-right: 15px;
	}
}
