.na-call-to-action-emphasis {
	display: inline;
	font-weight: 600;
}

.na-call-to-action-contact-info a {
	color: #000000;
}

.na-section-call-to-action {
	font-size: 20px;
	font-weight: 400;
}

.na-call-to-action-button {
	margin-top: 100px;
	background-color: #9BA689;
	color: #ffffff;
	font-size: large;
	font-weight: 700;
	border-radius: 1.0rem;
}

.na-call-to-action-button:hover {
	background-color: #b3be9f;
}

/* for phones */
@media (max-width: 415px) {
	.na-section-call-to-action {
		font-size: 12px;
	}

	.na-call-to-action-button {
		margin-top: 20px;
		background-color: #9BA689;
		color: #ffffff;
		font-size: 14px;
		font-weight: 700;
		border-radius: 1.0rem;
		padding: 10px;
		margin-left: 20px;
		margin-right: 20px;
	}

	.na-call-to-action-contact-info {
		padding-left: 50px;
		padding-right: 50px;
	}

	.na-call-to-action-reach-out {
		padding-left: 50px;
	}
}