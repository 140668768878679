.na-section-target-patient {
	padding-top: 100px;
	padding-bottom: 100px;
	background-color: #e2e2db;
}

.na-target-patient-copy {
	padding-top: 20px;
	font-size: 18px;
	font-weight: 500;
}

.na-target {
	text-align: center;
	font-size: 18px;
	font-weight: 500;
}

.na-target .row {
	padding-top: 25px;
	padding-bottom: 25px;
}

/* for phones */
@media (max-width: 415px) {
	.na-target-patient-copy {
		font-size: 12px;
	}

	.na-section-target-patient {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}