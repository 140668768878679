.na-faq {
	background-color: #e2e2db;
	padding-top: 60px;
	padding-bottom: 100px;
}

.na-faq-header {
	font-size: 28px;
	font-weight: 600;
	padding-bottom: 40px;
}

.na-faq-q {
	font-size: 18px;
	font-weight: 500;
	padding-bottom: 10px;
}

.na-faq-a {
	font-size: 15px;
}

.na-faq-p {
	padding-bottom: 15px;
}

.na-faqlist-collapse {
	width: 100%;
}

/* for phones */
@media (max-width: 415px) {
	.na-faq {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.na-faq-header {
		font-size: 20px;
		padding-bottom: 10px;
	}

	.na-faq-q {
		font-size: 14px;
		font-weight: 400;
	}
}