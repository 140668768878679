.na-section-payment-options {
	background-color: #e2e2db;
	font-size: 18px;
	padding-top: 100px;
	padding-bottom: 100px;
}

.na-payment-options-question {
	font-size: 20px;
	font-weight: 500;
	padding-bottom: 10px;
}

.na-payment-options-emphasis {
	display: inline;
	font-weight: 500;
}

.na-payment-options-header {
	font-size: 26px;
	font-weight: 500;
	padding-bottom: 10px;
}

/* for phones */
@media (max-width: 415px) {
	.na-section-payment-options {
		background-color: #e2e2db;
		font-size: 12px;
		padding-top: 20px;
		padding-bottom: 50px;
	}

	.na-payment-options-header {
		font-size: 20px;
	}

	.na-payment-options-copy {
		text-align: justify;
	}

	.na-payment-options-question {
		font-size: 14px;
	}
}