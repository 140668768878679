.na-staff-image {
	opacity: 0.8;
	height: 500px;
	object-fit: cover;
}

.na-staff {
	padding-bottom: 100px;
}

.na-staff-emphasis {
	display: inline;
	color: #c3d1b1;
}

.na-staff-faces {
	height: 500px;
}

.na-staff {
	font-size: 26px;
	color: #ffffff;
	font-weight: 500;
}

/* for phones */
@media (max-width: 415px) {
	.na-staff-faces {
		height: 120px;
	}

	.na-staff {
		font-size: 10px;
	}

	.na-staff {
		padding-bottom: 10px;
	}

	.na-staff-image {
		height: 100px;
	}
}