.na-staff-bios {
	background-color: #e2e2db;
	padding-top: 20px;
	padding-bottom: 100px;
}

.na-bio-headshot {
	border-radius: 50%;
	max-width: 300px;
}

.na-bio-name {
	text-align: center;
	font-size: 30px;
}

.na-bio-title {
	text-align: center;
	font-size: 22px;
}

.na-bio-contact {
	text-align: center;
	font-size: 16px;
	padding-bottom: 15px;
}

a.na-bio-contact-link {
	color: gray;
}

.na-bio {
	font-size: 18px;
	text-align: justify;	
}

.na-staff-bios-lead {
	font-size: 20px;
	padding-bottom: 50px;
}

.na-bio-col {
	margin-left: 15px;
	margin-right: 15px;
	text-align: center;
}

.na-staff-bios-header {
	font-size: 26px;
	font-weight: 500;
	padding-bottom: 10px;
}

/* for phones */
@media (max-width: 415px) {

	.na-bio-headshot {
		width: 70%;
	}

	.na-bio-name {
		padding-top: 20px;
		font-size: 22px;
	}

	.na-bio-title {
		font-size: 18px;
	}	

	.na-staff-bios {
		padding-bottom: 20px;
	}

	.na-staff-bios-lead {
		font-size: 16px;
		text-align: justify;
	}

	.na-bio {
		font-size: 14px;
	}

	.na-bio-col {
		padding-bottom: 40px;
	}

	.na-staff-bios-header {
		font-size: 20px;
	}
}
