.na-section-testimonials {
	background-color: #e2e2db;
	height: 400px;
	font-size: 16px;
	padding-bottom: 40px;
}

.na-testimonial-carousel .carousel-inner {
	box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.0);
}


@media (max-width:991px) {
	.na-section-testimonials {
		height: 500px;
	}
}

/* for phones */
@media (max-width: 415px) {
	.na-section-testimonials {
		font-size: 12px;
		height: 600px;
	}

	.na-section-testimonials h2 {
		font-size: 22px;
	}
}