.na-section-strive {
	font-size: 20px;
	font-weight: 500;
	text-align: justify;
}

.na-section-strive .col {
	padding-right: 30px;
	padding-left: 30px;
}

.na-strive-button {
	background-color: #9BA689;
	color: #ffffff;
	font-size: large;
	font-weight: 700;
	border-radius: 1.0rem;
}

.na-strive-button:hover {
	background-color: #b3be9f;
}

.na-strive-schedule {
	display: flex;
	align-items: center;
}

.na-empowered {
	display: inline;
	color: #035880;
}

.na-life-is-better {
	text-transform: lowercase;
	font-family: Brittany;
	font-size: 32px;
	text-align: center;
}

/* for phones */
@media (max-width: 415px) {
	.na-section-strive {
		font-size: 14px;
	}

	.na-life-is-better {
		font-size: 20px;
		margin-bottom: 10px;
	}

	.na-strive-button {
		padding-top: 5px;
		padding-bottom: 5px;
	}
}
