.na-above-fold-content {
	padding-top: 150px;
}

.na-page-header.page-header-small {
	min-height: 1000px;
}

.na-page-header-image {
	opacity: 0.7;
	object-fit: cover;
}

.na-lead-logo {
	width: 25%;
	margin-top: -65px;
}

.na-lead-logo img {
	position: relative;
	width: 300px;
	height: 300px;
	display: block;
    margin-right: auto;
	min-width: 300px;
	margin-right: 10px;
	margin-left: auto;
	margin-top: 20%;
}

h1.na-lead {
	font-family: Montserrat;
	text-transform: uppercase;
	color: #ffffff;
	text-shadow: 0px 1px #584446;
	font-weight: 500;
}

h3.na-lead {
	font-family: Montserrat;
	text-transform: lowercase;
	color: #ffffff;
	text-shadow: 0px 1px #584446;
	font-weight: 500;
}

.na-lead-emphasis {
	display: inline;
	text-shadow: 2px 2px 1px #9BA689;
	color: #ffffff;
	font-weight: bold;
}

.na-header-button {
	background-color: #9BA689;
	color: #ffffff;
	margin-left: 40px;
	margin-right: 40px;
	font-size: large;
	font-weight: 700;
	border-radius: 1.0rem;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 60px;
	padding-right: 60px;
}

.na-header-button:hover {
	background-color: #b3be9f;
}

.na-tagline h1 {
	padding-top: 15%;
	font-family: Brittany;
	text-transform: lowercase;
}

.na-small-logo {
	display: none;
}

/* for phones */
@media (max-width: 415px) {

	.na-page-header.page-header-small {
		min-height: 800px;
	}

	.page-header .content-center {
		top: 45%;
	}

	.na-page-header-image {
		opacity: 0.7;
		object-fit: cover;
	}

	.na-header-button {
		margin-left: 0px;
		margin-right: 0px;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.na-above-fold-content {
		padding-top: 20px;
	}

	h1.na-lead {
		font-size: 30px;
	}

	h3.na-lead {
		font-size: 18px;
	}
	
	.na-tagline {
		display: none;
	}

	.na-small-logo {
		display: block;
		padding-bottom: 10px;
	}
}
