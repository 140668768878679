
.na-section-maternal {
	padding-top: 40px;
	padding-bottom: 40px;
}

.na-maternal {
	color: #584446;
	font-size: 22px;
	font-weight: 500;
}

.na-maternal-blocks {
	padding-top: 20px;
	text-align: center;
}

.na-maternal-block-content {
	padding-top: 10px;
	padding-left: 15px;
	padding-right: 15px;
	font-size: 16px;
}

.na-maternal-emphasis {
	color: #b6b688;
	text-transform: uppercase;
	display: inline;
}


/* for phones */
@media (max-width: 415px) {
	.na-maternal {
		text-align: justify;
	}
}