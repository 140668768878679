.na-footer.footer-default {
	color: #ffffff;
	background-color: #5d6754;
	font-size: 20px;
	font-weight: 500;
	padding-top: 50px;
}

.na-footer-title {
	text-transform: uppercase;
	font-weight: 600;
	padding-bottom: 20px;
}

.na-footer-emphasis {
	color: #c8d1a1;
	font-weight: bold;
}

.na-location-spacer {
	padding:10px;
}

.na-footer-small {
	font-size: .7em;
}

.na-footer-social-media-links {
	letter-spacing: 1em;
}

.na-copyright {
	padding-top: 10px;
	float: unset;
	font-size: 0.6em;
	text-align: center;
}

.na-footer a {
	color: #ffffff;
}

.na-footer a:visited {
	color: #ffffff;
}

.fa-facebook-square {
	color: #3b5998;
	background-image: linear-gradient( to bottom, transparent 20%, white 20%, white 93%, transparent 93% );
	background-size: 55%;
	background-position: 70% 0;
	background-repeat: no-repeat;
}

.fa-instagram {
	background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%); 
	background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
	border-radius: 10px;
}

/* for phones */
@media (max-width: 415px) {
	.na-footer.na-footer.footer-default {
		font-size: 12px;
	}

	.fa-facebook-square {
		background-size: 100%;
		background-position: 100% 0;
		border-radius: 10px;
	}

	.fa-instagram {
		border-radius: 3px;
	}

	.na-location-spacer {
		padding: 0px;
	}
	
	.na-location-one {
		padding-right: 0px;
	}

	.na-location-two {
		padding-left: 0px;
	}

	.na-location-small-row {
		padding-bottom: 35px;
	}

	.na-footer-connect-col {
		padding-right: 0px;
	}

	.na-footer-links-col {
		padding-left: 0px;

	}

	.na-footer-base-row {
		padding-bottom: 20px;
	}
}