.na-download-guide-image {
	opacity: 0.8;
	height: 500px;
	object-fit: cover;
}

.na-download-guide-heading {
	font-weight: 600;
	padding-bottom: 50px;
}

.na-download-guide-button {
	background-color: #b8a097;
	color: #ffffff;
	font-size: large;
	font-weight: 700;
	border-radius: 1.0rem;
	margin-top: 75px;
}

.na-download-guide-button:hover {
	background-color: #b8a097;
	filter: brightness(85%);
}

.na-download-guide {
	color: #ffffff;
	font-size: 26px;
	font-weight: 500;
	padding-top: 100px;
	padding-bottom: 100px;
}

/* for phones */
@media (max-width: 415px) {
	.na-download-guide {
		font-size: 14px;
	}
}