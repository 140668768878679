.na-pricing-table {
	background-color: #ffffff;
	margin-top: 50px;
	margin-bottom: 50px;
	margin-left: 10px;
	margin-right: 10px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 50px;
	padding-bottom: 50px;
	font-size: 15px;
}

.na-pricing-table-head-1 {
	text-transform: uppercase;
	font-size: 28px;
	color: #5C6652;
	font-weight: 600;
	text-align: center;
}

.na-pricing-table-head-2-emphasis {
	display: inline;
	font-weight: 500;
}

.na-pricing-table-head-2 {
	font-size: 24px;
	color: #B8A097;
	font-weight: 400;
	text-align: center;
}

.na-pricing-table-bold {
	display: inline;
	font-weight: 500;
}

.na-pricing-table-description {
	padding-top: 10px;
	text-align: justify;
}

.na-pricing-table-italic {
	display: inline;
	font-style: italic;
}

.na-pricing-table-header {
	text-align: center;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 500;
}

.na-pricing-table-row-header {
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 500;
	text-align: left;
}

.na-pricing-table-header-row {
	padding-top: 30px;
}

.na-pricing-table-row {
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.na-pricing-table-dark-row {
	background-color: #CDD3C4;
}

.na-pricing-table-footer {
	padding-top: 10px;
	text-align: center;
	font-size: 12px;
}

.na-pricing-table-footer-emphasis {
	font-weight: 500;
}

.na-pricing-table-tiny-font {
	font-size: 11px;
	text-transform: none;
}

.na-pricing-branding-footer {
	padding-top: 10px;
	text-transform: uppercase;
	text-align: center;
	font-size: 28px;
	color: #5C6652;
	font-weight: 550;
	text-decoration: none;
}

a.na-pricing-branding-footer {
	color: #5C6652;
}

.na-pricing-lifeisbetter {
	text-transform: lowercase;
	font-family: Brittany;
	text-align: center;
	font-size: 22px;
	color: #B8A097;
}

/* for phones */
@media (max-width: 415px) {

	.na-pricing-branding-footer {
		font-size: 10px;
	}

	.na-pricing-lifeisbetter {
		padding-top: 10px;
		font-size: 18px;
	}

	.na-pricing-table-tiny-font {
		font-size: 6px;
	}

	.na-pricing-table-header-row {
		padding-top: 10px;
	}

	.na-pricing-table {
		padding-top: 10px;
		padding-bottom: 10px;
		margin-bottom: 20px;
		font-size: 6px;
	}

	.na-pricing-table-head-1 {
		font-size: 14px;
	}

	.na-pricing-table-head-2 {
		font-size: 10px;
	}

	.na-pricing-table-description {
		font-size: 7px;
	}

	.na-pricing-table-logo-col {
		padding-left: 0px;
		padding-right: 0px;
	}

	.na-pricing-table-header-col {
		padding-left: 0px;
		padding-right: 0px;
	}

	.na-pricing-table-header {
		font-size: 9px;
		padding-left: 2px;
		padding-right: 2px;
	}

	.na-pricing-table-row-header {
		font-size: 9px;
	}

	.na-pricing-table-row {
		padding-top: 2px;
		padding-bottom: 2px;
	}

	.na-pricing-table-footer {
		font-size: 6px;
	}

	.na-pricing-branding-footer {
		font-size: 11px;
	}

	.na-pricing-lifeisbetter {
		font-size: 16px;
	}
}