.na-three-services {
	text-align: justify;
	font-size: 18px;
}

.na-three-services img {
	border-radius: 20px;
}

.na-three-services-title {
	text-align: center;
	font-size: 22px;
	font-weight: 600;
}

.na-three-services-col {
	background-color: #b8a097;
	color: #ffffff;
	font-weight: 500;
	border-radius: 20px;
	padding-top: 20px;
	padding-bottom: 20px;
	margin-top: 20px;
	margin-bottom: 20px;
	margin-right: 10px;
	margin-left: 10px;
}

/* for phones */
@media (max-width: 415px) {
	.na-three-services-title {
		font-size: 14px;
	}

	.na-three-services {
		font-size: 12px;
	}

	.na-three-services-col {
		margin-bottom: 2px;
		margin-top: 2px;
		margin-left: 2px;
		margin-right: 2px;
	}
}